@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;700;900&display=swap");
@font-face {
  font-family: "Brandon Grotesque";
  font-weight: bold;
  src: url("../fonts/Brandon_bold.otf");
  src: local("Brandon Grotesque"), url("../fonts/Brandon_bold.woff") format("woff"), "../fonts/Brandon_bold.ttf" format("truetype"); }

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block; }

body {
  line-height: 1; }

ol,
ul {
  list-style: none; }

blockquote,
q {
  quotes: none; }

blockquote:before, blockquote:after {
  content: "";
  content: none; }

q:before, q:after {
  content: "";
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

main {
  display: block; }

button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  outline: none;
  appearance: none; }

input,
button,
select,
textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: transparent;
  border: none;
  border-radius: 0;
  font: inherit;
  outline: none; }

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: inherit; }

html {
  font-size: 62.5%;
  font-family: "Noto Sans JP", sans-serif;
  box-sizing: border-box;
  -webkit-text-size-adjust: 100%; }

body {
  color: #111;
  line-height: 1.5;
  letter-spacing: 0.064em; }

a {
  text-decoration: none;
  color: inherit;
  cursor: pointer; }

img {
  max-width: 100%;
  width: 100%;
  line-height: 1;
  vertical-align: bottom; }

input {
  outline: none; }

textarea {
  outline: none;
  resize: none; }

.wrap {
  position: relative;
  overflow: hidden;
  padding-right: 12rem; }
  @media (max-width: 1080px) {
    .wrap {
      padding-right: 9rem; } }
  @media (max-width: 768px) {
    .wrap {
      padding-right: 0;
      padding-bottom: 7.2rem; } }
  .wrap.is-no-contact {
    padding-right: 0; }
    @media (max-width: 768px) {
      .wrap.is-no-contact {
        padding-bottom: 0; } }

/* Components */
.btn {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  border-radius: 100px;
  background-color: #0032af;
  font-size: 1.6rem;
  font-weight: bold;
  color: #fff;
  text-align: center;
  border: 2px solid #0032af;
  transition: all 0.3s; }
  .btn-header {
    justify-content: center; }
    .btn-header:hover {
      background-color: #fff;
      color: #0032af; }
    @media (max-width: 768px) {
      .btn-header {
        font-size: 1.2rem; } }
  .btn-arrow {
    padding: 0 16px 0 24px; }
    .btn-arrow:hover {
      background-color: #fff;
      color: #0032af; }
      .btn-arrow:hover::after {
        background-image: url("../img/icon_arrow_right_blue.svg"); }
    .btn-arrow::after {
      content: "";
      width: 2.7rem;
      height: 1.5rem;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center center;
      background-image: url("../img/icon_arrow.svg"); }
  .btn-submit {
    justify-content: center; }
    .btn-submit:hover {
      background-color: #fff;
      color: #0032af; }

.container {
  width: 100%;
  max-width: 1020px;
  padding: 0 30px;
  margin: 0 auto; }
  @media (max-width: 768px) {
    .container {
      padding: 0 16px; } }

.marker {
  background-image: linear-gradient(90deg, #fce819, #fce819);
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: 100% 40%; }

.color-orange {
  color: #fc8519; }

.color-main {
  color: #0032af; }

.color-main-2 {
  color: #004ad8; }

.color-main-d {
  color: #0a2d83; }

.fw-bold {
  font-weight: bold; }

.double-line {
  background-image: linear-gradient(0deg, #fce819 0, #fce819 2px, #fff 2px, #fff 4px, #fce819 4px, #fce819 6px, #fff 6px, #fff 100%);
  background-repeat: no-repeat;
  background-position: left bottom;
  background-size: 100% 100%;
  padding-bottom: 6px; }

.font-bg {
  font-family: "Brandon Grotesque", "Noto Sans JP", sans-serif; }

.break {
  display: inline-block; }

.bg-lines {
  background-color: #f5f5f5;
  background-image: url("../img/bg_lines.png");
  background-size: 1440px 1209px;
  background-repeat: repeat;
  background-position: center center; }

.bracket {
  position: relative;
  font-size: 2.4rem;
  font-weight: bold;
  letter-spacing: 0.064em;
  text-align: center;
  line-height: 1.67; }
  @media (max-width: 768px) {
    .bracket {
      font-size: 1.8rem;
      padding: 30px 0; } }
  .bracket::before, .bracket::after {
    content: "";
    position: absolute;
    width: 44px;
    height: 40px;
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat; }
    @media (max-width: 768px) {
      .bracket::before, .bracket::after {
        width: 30px;
        height: 27px; } }
  .bracket::before {
    background-image: url("../img/bracket_left.svg");
    left: 0;
    top: 0; }
  .bracket::after {
    background-image: url("../img/bracket_right.svg");
    right: 0;
    bottom: 0; }
  .bracket__quote-icon {
    width: 35px;
    height: 29px;
    background-image: url("../img/icon_quote.svg");
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    display: block;
    position: absolute;
    right: 38px;
    top: 0; }
    @media (max-width: 768px) {
      .bracket__quote-icon {
        right: 10px;
        width: 30px;
        height: 25px; } }
  .bracket--white {
    color: #fff; }
    .bracket--white::before {
      background-image: url("../img/bracket_left_white.svg"); }
    .bracket--white::after {
      background-image: url("../img/bracket_right_white.svg"); }
    .bracket--white .bracket__quote-icon {
      opacity: 0.32; }
  .bracket p {
    letter-spacing: 0.064em; }

.scrollable {
  position: relative; }
  @media (max-width: 768px) {
    .scrollable {
      overflow-x: scroll; } }
  .scrollable__indicator {
    display: none;
    position: absolute;
    left: 20px;
    top: 60px;
    align-items: center;
    font-family: "Brandon Grotesque", "Noto Sans JP", sans-serif;
    font-size: 1.4rem;
    letter-spacing: 0.08em;
    color: #0032af; }
    .scrollable__indicator::after {
      content: "";
      width: 27px;
      height: 12px;
      background-image: url(../img/icon_arrow_right_orange.svg);
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center center;
      margin-left: 6px;
      animation: slide-right 1.2s infinite; }
    @media (max-width: 768px) {
      .scrollable__indicator {
        display: flex; } }

@keyframes slide-right {
  0% {
    transform: translate(0, -1px);
    opacity: 1; }
  100% {
    transform: translate(10px, -1px); } }

.table {
  position: relative;
  width: 100%;
  border-collapse: collapse; }
  .table::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 19.6%;
    height: calc(100% + 14px);
    border-radius: 4px 4px 0 0;
    width: 26.8%;
    box-shadow: 0 0 20px rgba(7, 37, 140, 0.31); }
  .table tr:last-child {
    border-bottom: 1px solid #a1accb; }
  .table th,
  .table td {
    width: 26.8%;
    text-align: center;
    vertical-align: middle;
    font-size: 1.3rem;
    line-height: 1.615; }
    @media (max-width: 768px) {
      .table th,
      .table td {
        font-size: 1rem; } }
  .table th {
    border-right: 1px solid #a1accb;
    height: 70px;
    font-size: 1.6rem;
    font-weight: bold; }
    @media (max-width: 768px) {
      .table th {
        font-size: 1.1rem;
        height: 58px; } }
  .table td {
    padding: 22px 12px 16px;
    border-right: 1px solid #a1accb; }
    @media (max-width: 768px) {
      .table td {
        padding: 16px 6px 10px; } }
    .table td p + p {
      margin-top: 12px; }
      @media (max-width: 768px) {
        .table td p + p {
          margin-top: 3px; } }
  .table th:first-child,
  .table td:first-child {
    width: 19.6%; }
  .table tr:first-child th {
    border-top: 1px solid #a1accb; }
    .table tr:first-child th:first-child {
      border-top: 0;
      border-right: 0; }
  .table tr:first-child td:first-child {
    border-right: 0; }
  .table tr > td:first-child {
    border-left: 1px solid #a1accb;
    border-right: 0; }
  .table tr:nth-child(2) td:first-child {
    border-top: 1px solid #a1accb; }
  .table tr td:first-child {
    font-size: 1.6rem;
    font-weight: bold;
    letter-spacing: 0.064em; }
    @media (max-width: 768px) {
      .table tr td:first-child {
        font-size: 1.1rem; } }
  .table .fs-m {
    font-size: 1.6rem; }
    @media (max-width: 768px) {
      .table .fs-m {
        font-size: 1.3rem; } }
  .table .fs-l {
    font-size: 2rem; }
    @media (max-width: 768px) {
      .table .fs-l {
        font-size: 1.5rem; } }
  .table .fs-xl {
    font-size: 2.8rem;
    margin-right: 6px; }
    @media (max-width: 768px) {
      .table .fs-xl {
        font-size: 2rem;
        margin-right: 3px; } }
  .table .bg-gray-1 {
    background-color: #e7f0f8; }
  .table .bg-gray-2 {
    background-color: #f4f7fa; }
  .table .bg-main-1 {
    position: relative;
    background-color: #0032af;
    box-shadow: 0 0 20px rgba(7, 37, 140, 0.31);
    color: #fff;
    border-right: none; }
  .table .bg-main-2 {
    position: relative;
    background-color: #004ad8;
    box-shadow: 0 0 20px rgba(7, 37, 140, 0.31);
    color: #fff;
    border-right: none; }
  .table .th-shiraha {
    position: relative;
    color: #fce819;
    font-size: 2.4rem; }
    @media (max-width: 768px) {
      .table .th-shiraha {
        font-size: 1.6rem; } }
    .table .th-shiraha::before {
      content: "";
      position: absolute;
      top: -15px;
      left: 0;
      width: 100%;
      height: 16px;
      background-color: #0032af;
      border-radius: 4px 4px 0 0; }
    .table .th-shiraha p {
      transform: translateY(-6px); }
  .table .table-icon {
    width: 24px;
    margin: 0 auto; }
    @media (max-width: 768px) {
      .table .table-icon {
        width: 20px; } }
    .table .table-icon.triangle {
      width: 28px; }
      @media (max-width: 768px) {
        .table .table-icon.triangle {
          width: 22px; } }
    .table .table-icon.cross {
      width: 18px; }
    .table .table-icon + p {
      margin-top: 8px; }
      @media (max-width: 768px) {
        .table .table-icon + p {
          margin-top: 3px; } }

.form__row {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap; }
  .form__row:not(:first-child) {
    margin-top: 24px; }
    @media (max-width: 768px) {
      .form__row:not(:first-child) {
        margin-top: 12px; } }

.form__label {
  min-width: 190px;
  font-size: 1.6rem;
  font-weight: bold;
  letter-spacing: 0.064em;
  padding-top: 16px;
  cursor: pointer; }
  @media (max-width: 768px) {
    .form__label {
      width: 100%;
      min-width: 0; } }
  .form__label--checkbox {
    position: relative;
    min-width: 0;
    padding-top: 0;
    font-weight: 400;
    cursor: pointer;
    text-align: center; }

.form__label-required {
  color: #0032af; }

.form input,
.form textarea {
  font-size: 1.4rem;
  font-weight: bold;
  letter-spacing: 0.064em;
  padding: 18px 24px;
  flex-grow: 1;
  background-color: #fff;
  border: 1px solid #c4c4c4;
  border-radius: 4px;
  transition: all 0.3s; }
  @media (max-width: 768px) {
    .form input,
    .form textarea {
      padding: 12px 16px;
      margin-top: 8px;
      font-size: 1.6rem; } }
  .form input::placeholder,
  .form textarea::placeholder {
    color: rgba(0, 0, 0, 0.24); }

.form input[type="checkbox"] {
  width: 24px;
  max-width: 24px;
  height: 24px;
  padding: 0;
  border-radius: 0;
  margin-right: 16px;
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  overflow: hidden;
  padding: 0;
  clip: rect(0, 0, 0, 0); }

.form textarea {
  height: 200px; }

.form__privacy {
  margin-top: 40px; }

.form__privacy-head {
  font-size: 1.6rem;
  font-weight: bold;
  text-align: center; }

.form__privacy-text {
  background-color: #fff;
  border: 1px solid #c4c4c4;
  border-radius: 4px;
  padding: 24px 40px;
  height: 200px;
  overflow-y: scroll;
  font-size: 1.4rem;
  letter-spacing: 0.064em;
  line-height: 1.86;
  margin-top: 16px; }
  @media (max-width: 768px) {
    .form__privacy-text {
      padding: 12px 16px; } }
  .form__privacy-text p:not(:first-child) {
    margin-top: 1.86em; }
    @media (max-width: 768px) {
      .form__privacy-text p:not(:first-child) {
        margin-top: 1.5em; } }

.form__privacy-check {
  margin-top: 24px;
  text-align: center; }

.form__check {
  padding-left: 40px; }
  @media (max-width: 768px) {
    .form__check {
      font-size: 1.4rem;
      padding-left: 32px; } }
  .form__check::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    display: inline-block;
    width: 24px;
    height: 24px;
    background-color: #fff;
    border: 1px solid #c4c4c4;
    transition: all 0.3s; }

.form__error {
  width: 100%;
  padding-left: 190px;
  font-size: 1.4rem;
  margin-top: 6px;
  color: #af0000; }
  @media (max-width: 768px) {
    .form__error {
      padding-left: 0; } }
  .form__error--privacy {
    padding-left: 0;
    text-align: center; }

.form input[type="checkbox"]:checked + .form__check::after {
  content: "";
  display: block;
  position: absolute;
  left: 4px;
  top: 5px;
  width: 16px;
  height: 8px;
  border-left: 2px solid #0032af;
  border-bottom: 2px solid #0032af;
  transform: rotate(-45deg); }

.form__btn {
  width: 360px;
  height: 64px;
  margin: 32px auto 0; }
  @media (max-width: 768px) {
    .form__btn {
      width: 100%;
      height: 56px;
      margin: 24px auto 0; } }
  .form__btn .btn[disabled] {
    background-color: #c4c4c4; }

.form__row:hover input,
.form__row:hover textarea {
  box-shadow: 0 0 16px rgba(0, 50, 175, 0.16); }

.form input:focus,
.form input:active,
.form textarea:focus,
.form textarea:active {
  border-color: #0032af; }

.form__check:hover::before {
  box-shadow: 0 0 16px rgba(0, 50, 175, 0.16); }

.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #fff;
  z-index: 100; }
  .header__container {
    max-width: 1340px;
    padding-top: 32px;
    padding-bottom: 32px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: all 0.3s; }
    @media (max-width: 768px) {
      .header__container {
        padding-top: 12px;
        padding-bottom: 12px; } }
  .header__logo {
    width: 208px; }
    @media (max-width: 768px) {
      .header__logo {
        width: 140px; } }
  .header__nav {
    display: flex;
    align-items: center; }
  .header__nav-list {
    display: flex; }
    @media (max-width: 1080px) {
      .header__nav-list {
        display: none; } }
  .header__nav-item {
    margin-right: 32px; }
  .header__nav-link {
    position: relative;
    display: inline-block;
    font-size: 1.6rem;
    letter-spacing: 0.064em;
    font-weight: bold;
    padding: 5px 0;
    transition: all 0.3s; }
    .header__nav-link::after {
      content: "";
      position: absolute;
      left: 0;
      bottom: 0;
      width: 0;
      height: 2px;
      background-color: #0032af;
      transition: all 0.3s ease-in-out; }
    .header__nav-link:hover {
      color: #0032af; }
      .header__nav-link:hover::after {
        width: 100%; }
  .header__contact-btn {
    height: 56px;
    width: 160px; }
    @media (max-width: 768px) {
      .header__contact-btn {
        height: 42px;
        width: 120px; } }
  .header.is-scroll {
    box-shadow: 0 4px 12px rgba(17, 17, 17, 0.06); }
    .header.is-scroll .header__container {
      padding-top: 16px;
      padding-bottom: 16px; }
      @media (max-width: 768px) {
        .header.is-scroll .header__container {
          padding-top: 12px;
          padding-bottom: 12px; } }

.footer {
  padding: 80px 0;
  background-color: #0a2d83; }
  @media (max-width: 768px) {
    .footer {
      padding: 40px 0; } }
  .footer__container {
    display: flex;
    justify-content: space-between;
    max-width: 1340px; }
    @media (max-width: 1080px) {
      .footer__container {
        flex-wrap: wrap; } }
  .footer__logo {
    width: 208px; }
    @media (max-width: 768px) {
      .footer__logo {
        width: 160px;
        margin: 0 auto; } }
  @media (max-width: 1080px) {
    .footer__info {
      margin-left: auto;
      margin-top: 20px; } }
  @media (max-width: 768px) {
    .footer__info {
      width: 100%;
      margin-top: 24px; } }
  .footer__nav-list {
    display: flex; }
    @media (max-width: 768px) {
      .footer__nav-list {
        width: 100%;
        flex-wrap: wrap;
        justify-content: center; } }
  .footer__nav-item {
    color: #fff;
    font-weight: bold;
    font-size: 1.6rem;
    letter-spacing: 0.064em;
    margin-left: 32px; }
    @media (max-width: 768px) {
      .footer__nav-item {
        margin-left: 8px;
        margin-right: 8px;
        margin-top: 8px;
        font-size: 1.4rem; } }
  .footer__nav-item-link {
    transition: all 0.3s; }
    .footer__nav-item-link:hover {
      opacity: 0.7; }
  .footer__copy {
    font-family: "Brandon Grotesque", "Noto Sans JP", sans-serif;
    font-size: 1.6rem;
    color: rgba(255, 255, 255, 0.56);
    margin-top: 16px;
    text-align: right; }
    @media (max-width: 768px) {
      .footer__copy {
        text-align: center;
        font-size: 1.4rem;
        margin-top: 24px; } }

.section__title {
  text-align: center; }

.section__title-jp {
  display: block;
  font-size: 3.6rem;
  font-weight: bold;
  letter-spacing: 0.08em;
  line-height: 1.55; }
  @media (max-width: 768px) {
    .section__title-jp {
      font-size: 2.4rem; } }

.section__title-en {
  display: block;
  font-family: "Brandon Grotesque", "Noto Sans JP", sans-serif;
  color: #0032af;
  font-size: 2.4rem;
  letter-spacing: 0.08em;
  line-height: 1.42;
  margin-top: 6px; }
  @media (max-width: 768px) {
    .section__title-en {
      font-size: 1.8rem;
      margin-top: 3px; } }

.section__intro {
  font-size: 1.8rem;
  letter-spacing: 0.04em;
  text-align: center;
  margin-top: 24px;
  line-height: 1.89; }
  @media (max-width: 768px) {
    .section__intro {
      font-size: 1.5rem;
      margin-top: 16px; }
      .section__intro p + p {
        margin-top: 1.89em; } }

.mv {
  position: relative;
  padding: 160px 0 78px; }
  @media (max-width: 768px) {
    .mv {
      padding: 80px 0; } }
  .mv__container {
    max-width: 1440px;
    position: relative; }
  .mv__label {
    color: #fff;
    display: block;
    font-weight: bold;
    font-size: 2.4rem;
    letter-spacing: 0.064em;
    line-height: 1.58;
    text-align: center; }
    @media (max-width: 768px) {
      .mv__label {
        font-size: 1.8rem; } }
  .mv__label-wrap {
    background-color: #004ad8;
    display: inline-block;
    padding: 5px 16px; }
    @media (max-width: 768px) {
      .mv__label-wrap {
        background-color: transparent; } }
    @media (max-width: 768px) {
      .mv__label-wrap span {
        display: inline-block;
        background-color: #004ad8;
        padding: 2px 8px;
        margin-top: 4px; } }
  .mv__title {
    font-size: 5.2rem;
    font-weight: bold;
    letter-spacing: 0.12em;
    text-align: center;
    margin-top: 16px; }
    @media (max-width: 768px) {
      .mv__title {
        font-size: 3.2rem; } }
    .mv__title .marker {
      background-size: 100% 19px; }
    .mv__title .stroke {
      color: #fce819;
      text-shadow: 1px 1px 0px #004ad8, -1px -1px 0px #004ad8, -1px 1px 0px #004ad8, 1px -1px 0px #004ad8, 1px 0px 0px #004ad8, -1px 0px 0px #004ad8, 0px 1px 0px #004ad8, 0px -1px 0px #004ad8;
      font-weight: 900;
      -webkit-text-stroke: 2px #004ad8; }
  .mv__title-img {
    height: 7.5rem;
    display: inline-block; }
    @media (max-width: 768px) {
      .mv__title-img {
        height: 4.6rem;
        margin-right: 4px; } }
    .mv__title-img img {
      height: 100%;
      width: auto; }
  .mv__text {
    text-align: center;
    letter-spacing: 0.064em;
    font-size: 1.6rem;
    font-weight: bold;
    line-height: 1.75;
    margin-top: 14px; }
  .mv__badges {
    display: flex;
    justify-content: center;
    margin-top: 40px; }
    @media (max-width: 768px) {
      .mv__badges {
        margin: 4px -16px 0;
        flex-wrap: wrap; } }
  .mv__badge {
    position: relative;
    height: 84px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 12px; }
    @media (max-width: 768px) {
      .mv__badge {
        width: auto;
        height: auto;
        margin: 12px 1vw 0; } }
    .mv__badge::before, .mv__badge::after {
      content: "";
      width: 36px;
      height: 84px;
      display: inline-block;
      background-size: contain;
      background-position: center center;
      background-repeat: no-repeat; }
      @media (max-width: 768px) {
        .mv__badge::before, .mv__badge::after {
          position: absolute;
          width: 18px;
          height: 41px; } }
    .mv__badge::before {
      background-image: url("../img/mv_badge_frame_left.svg"); }
      @media (max-width: 768px) {
        .mv__badge::before {
          left: 0; } }
    .mv__badge::after {
      background-image: url("../img/mv_badge_frame_right.svg"); }
      @media (max-width: 768px) {
        .mv__badge::after {
          right: 0; } }
  .mv__badge-text {
    font-size: 1.4rem;
    font-weight: bold;
    text-align: center;
    letter-spacing: 0.04em;
    line-height: 1.5; }
    @media (max-width: 768px) {
      .mv__badge-text {
        font-size: 1rem;
        padding: 0 16px;
        line-height: 1.2; } }
    .mv__badge-text .fs-m {
      font-size: 1.5rem;
      line-height: 1.5; }
      @media (max-width: 768px) {
        .mv__badge-text .fs-m {
          font-size: 1rem;
          line-height: 1.2; } }
    .mv__badge-text .fs-xl {
      font-size: 4rem;
      line-height: 1; }
      @media (max-width: 768px) {
        .mv__badge-text .fs-xl {
          font-size: 2.4rem; } }
  .mv__btn-wrap {
    text-align: center;
    margin-top: 40px; }
    @media (max-width: 768px) {
      .mv__btn-wrap {
        margin-top: 24px; } }
  .mv__btn {
    width: 280px;
    height: 64px;
    margin: 0 auto; }
    @media (max-width: 768px) {
      .mv__btn {
        height: 56px;
        width: 100%;
        max-width: 280px; } }
  .mv__btn-text {
    position: relative;
    margin-top: 8px;
    color: #004ad8;
    font-weight: bold;
    font-size: 1.4rem;
    letter-spacing: 0.064em;
    z-index: 1; }
  .mv__illust {
    position: absolute;
    right: 2.4rem;
    bottom: calc(-1.8% - 78px);
    max-width: 32.6rem;
    width: 22.64%; }
  @media (max-width: 768px) {
    .mv__badge:nth-child(3) .mv__badge-text {
      padding: 0 2.4vw; } }

.purpose {
  position: relative;
  background-color: #0a2d83;
  padding: 94px 0 72px; }
  @media (max-width: 768px) {
    .purpose {
      padding: 40px 0; } }
  .purpose::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: calc(100%);
    background-image: url("../img/bg_arrow.png");
    background-size: 1440px 826px;
    background-repeat: repeat;
    background-position: center center;
    display: block; }
  .purpose__container {
    position: relative; }
  .purpose__title {
    color: #fff;
    font-weight: bold;
    font-size: 3.6rem;
    letter-spacing: 0.08em;
    text-align: center; }
    @media (max-width: 768px) {
      .purpose__title {
        font-size: 2.4rem; } }
  .purpose__title-img {
    display: inline-block;
    height: 8rem;
    transform: translateY(10px); }
    @media (max-width: 768px) {
      .purpose__title-img {
        height: 6rem; } }
    .purpose__title-img img {
      height: 100%;
      width: auto; }
  .purpose__main {
    position: relative;
    max-width: 710px;
    width: 100%;
    margin: 45px auto 0; }
    @media (max-width: 768px) {
      .purpose__main {
        margin-top: 24px; } }
  .purpose__illust {
    width: 295px;
    margin: 0 auto;
    transform: translateX(-10px); }
    @media (max-width: 768px) {
      .purpose__illust {
        width: 100%;
        max-width: 295px; } }
  .purpose__speech {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%; }
    @media (max-width: 768px) {
      .purpose__speech {
        max-width: 350px;
        right: 0;
        margin: 0 auto; } }
  .purpose__speech-item {
    position: absolute;
    display: inline-block;
    background-color: #fff;
    color: #0a2d83;
    font-size: 1.6rem;
    font-weight: bold;
    letter-spacing: 0.064em;
    text-align: center;
    padding: 12px 32px;
    border-radius: 10px;
    border: 1px solid #0a2d83; }
    @media (max-width: 768px) {
      .purpose__speech-item {
        font-size: 1.3rem;
        padding: 8px; } }
    .purpose__speech-item::before, .purpose__speech-item::after {
      content: "";
      position: absolute;
      width: 0;
      height: 0;
      border-style: solid; }
    .purpose__speech-item:first-child {
      top: 4px;
      left: 15%; }
      @media (max-width: 768px) {
        .purpose__speech-item:first-child {
          left: 11%; } }
      .purpose__speech-item:first-child::before {
        border-width: 0 17px 9px 0;
        border-color: transparent #0a2d83 transparent transparent;
        bottom: -10px;
        right: 21px; }
      .purpose__speech-item:first-child::after {
        border-width: 0 20px 10px 0;
        border-color: transparent #fff transparent transparent;
        right: 22px;
        bottom: -8px; }
    .purpose__speech-item:nth-child(2) {
      bottom: 23.5%;
      left: 0; }
      @media (max-width: 768px) {
        .purpose__speech-item:nth-child(2) {
          bottom: 20.5%; } }
      .purpose__speech-item:nth-child(2)::before {
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 17px 9px 0 0;
        border-color: #0a2d83 transparent transparent transparent;
        right: -10px;
        top: 20px; }
      .purpose__speech-item:nth-child(2)::after {
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 20px 10px 0 0;
        border-color: #fff transparent transparent transparent;
        right: -8px;
        top: 21px; }
    .purpose__speech-item:nth-child(3) {
      bottom: 19%;
      right: 0; }
      @media (max-width: 768px) {
        .purpose__speech-item:nth-child(3) {
          bottom: 8%; } }
      .purpose__speech-item:nth-child(3)::before {
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 9px 0 0 17px;
        border-color: transparent transparent transparent #0a2d83;
        top: -10px;
        left: 31px; }
      .purpose__speech-item:nth-child(3)::after {
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 10px 0 0 20px;
        border-color: transparent transparent transparent #fff;
        top: -8px;
        left: 32px; }
  .purpose__bottom {
    position: relative;
    font-size: 2.4rem;
    font-weight: bold;
    color: #fff;
    text-align: center;
    letter-spacing: 0.064em;
    padding-top: 84px;
    margin-top: 24px; }
    @media (max-width: 768px) {
      .purpose__bottom {
        padding-top: 50px;
        margin-top: 16px; } }
    .purpose__bottom::before {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      margin: 0 auto;
      background-image: url("../img/icon_arrow_down.svg");
      background-size: contain;
      background-position: center center;
      background-repeat: no-repeat;
      width: 32px;
      height: 52px; }
      @media (max-width: 768px) {
        .purpose__bottom::before {
          width: 24px;
          height: 40px; } }
  .purpose__bottom-img {
    display: inline-block;
    height: 62px; }
    @media (max-width: 768px) {
      .purpose__bottom-img {
        height: 46px; } }
    .purpose__bottom-img img {
      height: 100%;
      width: auto; }

.keyword__intro {
  background-color: #fff;
  padding: 40px 0;
  text-align: center;
  font-size: 2.8rem;
  letter-spacing: 0.08em;
  color: #0a2d83;
  font-weight: bold; }
  @media (max-width: 768px) {
    .keyword__intro {
      font-size: 2rem;
      padding: 24px 0; } }

.keyword__content {
  background-color: #f5f5f5;
  background-image: url("../img/bg_lines.png");
  background-size: 1440px 1209px;
  background-repeat: repeat;
  background-position: center center;
  padding: 68px 0 100px; }
  @media (max-width: 768px) {
    .keyword__content {
      padding: 40px 0; } }

.keyword__title {
  font-size: 3.6rem;
  font-weight: bold;
  letter-spacing: 0.08em;
  text-align: center; }
  @media (max-width: 768px) {
    .keyword__title {
      font-size: 2.4rem; } }

.keyword__title-img {
  height: 59px;
  display: inline-block; }
  @media (max-width: 768px) {
    .keyword__title-img {
      height: 40px; } }
  .keyword__title-img img {
    width: auto;
    height: 100%; }

.keyword__box {
  background-color: #fff;
  padding: 40px;
  margin-top: 32px; }
  @media (max-width: 768px) {
    .keyword__box {
      padding: 24px 16px;
      margin-top: 24px; } }

.keyword__box-text {
  font-size: 1.6rem;
  font-weight: bold;
  letter-spacing: 0.064em;
  text-align: center; }
  @media (max-width: 768px) {
    .keyword__box-text {
      font-size: 1.4rem; } }

.keyword__box-diagram {
  max-width: 720px;
  width: 100%;
  margin: 16px auto 0; }

.keyword__bracket {
  margin-top: 56px; }
  @media (max-width: 768px) {
    .keyword__bracket {
      margin-top: 24px; } }
  .keyword__bracket .fs-l {
    font-size: 3.2rem;
    font-weight: bold; }
    @media (max-width: 768px) {
      .keyword__bracket .fs-l {
        font-size: 2.4rem; } }
  .keyword__bracket .bg {
    background-color: #0032af;
    color: #fff;
    padding: 2px 8px; }
    @media (max-width: 768px) {
      .keyword__bracket .bg {
        background-color: transparent; }
        .keyword__bracket .bg span {
          font-size: 2.2rem;
          display: inline-block;
          background-color: #0032af;
          padding: 2px 4px;
          margin-top: 4px; } }
  .keyword__bracket p {
    position: relative; }
  .keyword__bracket p:not(:first-of-type) {
    margin-top: 30px; }
    @media (max-width: 768px) {
      .keyword__bracket p:not(:first-of-type) {
        margin-top: 16px; } }

.keyword__bracket-illust {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 18.125%; }
  @media (max-width: 768px) {
    .keyword__bracket-illust {
      width: 16%; } }

.point {
  padding: 100px 0 78px; }
  @media (max-width: 768px) {
    .point {
      padding: 40px 0; } }
  .point__container {
    max-width: 1180px; }
  .point__list {
    margin-top: 24px; }
  .point__item {
    display: flex; }
    @media (max-width: 768px) {
      .point__item {
        flex-direction: column; } }
  .point__img {
    width: 35.7%;
    flex-shrink: 0; }
    @media (max-width: 1080px) {
      .point__img {
        width: 32%; } }
    @media (max-width: 768px) {
      .point__img {
        max-width: 220px;
        width: 100%;
        margin: 0 auto; } }
  .point__content {
    flex-grow: 1;
    max-width: calc(64.3% - 134px); }
    @media (max-width: 1080px) {
      .point__content {
        max-width: 100%; } }
    @media (max-width: 768px) {
      .point__content {
        width: 100%;
        max-width: 560px;
        margin: -24px auto 0; } }
  .point__num {
    font-family: "Brandon Grotesque", "Noto Sans JP", sans-serif;
    font-size: 8rem;
    color: rgba(252, 133, 25, 0.24);
    margin-bottom: -58px;
    text-align: right;
    letter-spacing: 0; }
    @media (max-width: 768px) {
      .point__num {
        font-size: 5.6rem;
        margin-bottom: -18px; } }
  .point__title {
    font-size: 3.2rem;
    letter-spacing: 0.08em;
    font-weight: bold;
    color: #0032af; }
    @media (max-width: 768px) {
      .point__title {
        font-size: 2.4rem; } }
    .point__title .bg {
      background-color: #0032af;
      color: #fff; }
  .point__text {
    font-size: 1.8rem;
    letter-spacing: 0.04em;
    line-height: 1.89;
    margin-top: 20px; }
    @media (max-width: 768px) {
      .point__text {
        font-size: 1.4rem;
        margin-top: 12px; } }
  .point__item:nth-child(odd) {
    justify-content: flex-start; }
    .point__item:nth-child(odd) .point__img {
      margin-right: 54px; }
      @media (max-width: 1080px) {
        .point__item:nth-child(odd) .point__img {
          margin-right: 40px; } }
      @media (max-width: 768px) {
        .point__item:nth-child(odd) .point__img {
          margin-right: auto; } }
  .point__item:nth-child(even) {
    flex-direction: row-reverse; }
    @media (max-width: 768px) {
      .point__item:nth-child(even) {
        flex-direction: column; } }
    .point__item:nth-child(even) .point__img {
      margin-left: 54px; }
      @media (max-width: 1080px) {
        .point__item:nth-child(even) .point__img {
          margin-left: 40px; } }
      @media (max-width: 768px) {
        .point__item:nth-child(even) .point__img {
          margin-left: auto; } }
  .point__item:not(:first-child) {
    margin-top: 32px; }
    @media (max-width: 768px) {
      .point__item:not(:first-child) {
        margin-top: 24px; } }
  .point__bracket {
    margin-top: 64px; }
    @media (max-width: 768px) {
      .point__bracket {
        margin-top: 32px; } }
    .point__bracket .fs-l {
      font-size: 3.6rem;
      letter-spacing: 0.08em;
      color: #0032af; }
      @media (max-width: 768px) {
        .point__bracket .fs-l {
          font-size: 2.4rem; } }
    .point__bracket .p-hr {
      padding: 0 0.2em; }

.follow {
  background-color: #f0f7ff;
  padding: 100px 0 94px; }
  @media (max-width: 768px) {
    .follow {
      padding: 40px 0; } }
  .follow__list {
    margin-top: 40px; }
    @media (max-width: 768px) {
      .follow__list {
        margin-top: 24px; } }
  .follow__item {
    display: flex;
    align-items: center; }
    @media (max-width: 768px) {
      .follow__item {
        max-width: 600px;
        margin: 0 auto;
        flex-wrap: wrap; } }
    .follow__item:not(:first-child) {
      margin-top: 40px; }
      @media (max-width: 768px) {
        .follow__item:not(:first-child) {
          margin-top: 24px; } }
  .follow__img {
    width: 35.4%;
    flex-shrink: 0;
    margin-right: 40px; }
    @media (max-width: 768px) {
      .follow__img {
        width: 100%;
        margin-right: 0; } }
  .follow__title {
    font-size: 2.8rem;
    font-weight: bold;
    letter-spacing: 0.08em;
    color: #0032af; }
    @media (max-width: 768px) {
      .follow__title {
        font-size: 2rem;
        margin-top: 12px; } }
    .follow__title .marker {
      background-size: 100% 12px;
      padding-bottom: 2px; }
  .follow__text {
    font-size: 1.8rem;
    letter-spacing: 0.04em;
    line-height: 1.89;
    margin-top: 15px; }
    @media (max-width: 768px) {
      .follow__text {
        font-size: 1.4rem; } }

.eval {
  position: relative;
  padding: 80px 0 54px;
  background-color: #0032af; }
  @media (max-width: 768px) {
    .eval {
      padding: 40px 0; } }
  .eval::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: calc(100%);
    background-image: url("../img/bg_arrow.png");
    background-size: 1440px 826px;
    background-repeat: repeat;
    background-position: center center;
    display: block; }
  .eval__container {
    position: relative; }
  .eval__title {
    color: #fff;
    font-weight: bold;
    font-size: 3.6rem;
    letter-spacing: 0.08em;
    text-align: center; }
    @media (max-width: 768px) {
      .eval__title {
        font-size: 2.4rem; } }
  .eval__list {
    display: flex;
    justify-content: space-between;
    margin-top: 36px; }
    @media (max-width: 768px) {
      .eval__list {
        flex-wrap: wrap;
        max-width: 600px;
        margin-left: auto;
        margin-right: auto;
        margin-top: 24px; } }
  .eval__item {
    width: 30.52%;
    display: flex;
    flex-direction: column; }
    @media (max-width: 768px) {
      .eval__item {
        width: 100%; } }
    @media (max-width: 768px) {
      .eval__item:not(:first-child) {
        margin-top: 24px; } }
  .eval__item-head {
    color: #fff;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2rem;
    letter-spacing: 0.064em;
    height: 48px;
    margin-bottom: 4px; }
    @media (max-width: 768px) {
      .eval__item-head {
        height: auto;
        font-size: 1.8rem; } }
    .eval__item-head--shiraha {
      font-family: "Brandon Grotesque", "Noto Sans JP", sans-serif;
      font-size: 3.4rem;
      color: #fce819; }
      @media (max-width: 768px) {
        .eval__item-head--shiraha {
          font-size: 2.4rem; } }
      .eval__item-head--shiraha::before, .eval__item-head--shiraha::after {
        content: "";
        height: 48px;
        width: 21px;
        background-size: contain;
        background-position: center center;
        background-repeat: no-repeat;
        display: block;
        background-image: url("../img/eval_frame.svg"); }
        @media (max-width: 768px) {
          .eval__item-head--shiraha::before, .eval__item-head--shiraha::after {
            height: 38px;
            width: 16px; } }
      .eval__item-head--shiraha::before {
        transform: scale(-1, 1);
        margin-right: 6px; }
      .eval__item-head--shiraha::after {
        margin-left: 6px; }
  .eval__card {
    display: flex;
    flex-direction: column;
    flex-grow: 1; }
  .eval__card-diagram {
    position: relative;
    padding: 24px 15px;
    background-color: #fff;
    border: 1px solid #0a2d83;
    border-radius: 8px 8px 0 8px; }
  .eval__card-text {
    background-color: #fff;
    border: 1px solid #0a2d83;
    padding: 24px 24px 16px;
    margin-top: -8px;
    border-radius: 0 0 8px 8px;
    font-size: 1.6rem;
    font-weight: bold;
    line-height: 1.75;
    flex-grow: 1; }
    @media (max-width: 768px) {
      .eval__card-text {
        font-size: 1.4rem; } }
  .eval__bracket {
    margin-top: 56px; }
    @media (max-width: 768px) {
      .eval__bracket {
        margin-top: 32px; } }
    .eval__bracket .fs-l {
      font-size: 3.2rem;
      color: #fce819; }
      @media (max-width: 768px) {
        .eval__bracket .fs-l {
          font-size: 2.4rem; } }

.plan {
  padding: 122px 0 40px; }
  @media (max-width: 768px) {
    .plan {
      padding: 40px 0; } }
  .plan__table {
    margin-top: 56px; }
    @media (max-width: 768px) {
      .plan__table {
        width: 670px;
        display: flex;
        margin-top: 56px;
        padding: 0 0 31px; } }
    @media (max-width: 768px) {
      .plan__table::after {
        content: "";
        width: 20px;
        height: 1px;
        background-color: transparent;
        display: inline-block; } }
  .plan__sub-info {
    font-size: 1.6rem;
    text-align: center;
    margin-top: 24px; }
  .plan__scrollable {
    width: 100%;
    max-width: 1020px;
    padding: 0 30px;
    margin: 0 auto; }
    @media (max-width: 1080px) {
      .plan__scrollable {
        padding-left: 60px; } }
    @media (max-width: 768px) {
      .plan__scrollable .scrollable__indicator {
        top: 70px; } }
  .plan__illust {
    position: absolute;
    width: 26.2%;
    bottom: -32%;
    left: -17%; }
    @media (max-width: 1080px) {
      .plan__illust {
        width: 120px;
        left: -20px;
        bottom: -20px; } }
    @media (max-width: 768px) {
      .plan__illust {
        width: 106px;
        left: 0;
        bottom: 0; } }

.service {
  padding: 54px 0 110px;
  overflow: hidden; }
  @media (max-width: 768px) {
    .service {
      padding: 40px 0; } }
  .service__table {
    margin-top: 56px; }
    @media (max-width: 768px) {
      .service__table {
        width: 670px;
        display: flex;
        margin-top: 48px;
        padding: 0 0 14px; } }
    @media (max-width: 768px) {
      .service__table::after {
        content: "";
        width: 20px;
        height: 1px;
        background-color: transparent;
        display: inline-block; } }
    @media (max-width: 768px) {
      .service__table table td {
        padding: 8px 6px 7px; } }
  .service__scrollable {
    width: 100%;
    max-width: 1020px;
    padding: 0 30px;
    margin: 0 auto; }
    @media (max-width: 1080px) {
      .service__scrollable {
        padding-right: 50px; } }
    @media (max-width: 768px) {
      .service__scrollable {
        overflow-x: scroll;
        margin-top: 0;
        padding-right: 30px;
        padding-left: 60px; } }
  .service__illust {
    position: absolute;
    width: 22.8%;
    bottom: -70%;
    right: -17%; }
    @media (max-width: 1080px) {
      .service__illust {
        width: 120px;
        right: -40px;
        bottom: -40px; } }
    @media (max-width: 768px) {
      .service__illust {
        width: 98px;
        transform: scale(-1, 1);
        left: -3px;
        right: auto;
        bottom: 0; } }
  .service__bracket {
    margin-top: 64px; }
    .service__bracket .fs-l {
      font-size: 3.2rem; }
      @media (max-width: 768px) {
        .service__bracket .fs-l {
          font-size: 2.4rem; } }

.voice {
  padding: 94px 0 0; }
  .voice__cards {
    display: flex;
    justify-content: space-between;
    margin-top: 38px; }
  .voice__card {
    width: 48%;
    display: flex;
    flex-direction: column; }
  .voice__card-top {
    position: relative;
    border-radius: 8px 8px 0 0;
    overflow: hidden; }
  .voice__card-corp {
    position: absolute;
    right: 24px;
    bottom: 8px;
    background-color: #fff;
    font-size: 1.4rem;
    font-weight: bold;
    letter-spacing: 0.064em;
    padding: 10px 16px;
    border-radius: 4px; }
  .voice__card-bottom {
    background-color: #fff;
    border-radius: 0 0 8px 8px;
    padding: 24px;
    display: flex;
    flex-wrap: wrap;
    flex-grow: 1; }
  .voice__card-title {
    width: 100%;
    font-size: 1.8rem;
    letter-spacing: 0.04em;
    font-weight: bold;
    color: #0032af;
    letter-spacing: 0.04em;
    line-height: 1.89; }
  .voice__card-text {
    width: 100%;
    font-size: 1.4rem;
    line-height: 2;
    letter-spacing: 0.064em;
    margin-top: 10px;
    margin-bottom: 16px; }
  .voice__card-info {
    width: 100%;
    color: rgba(17, 17, 17, 0.32);
    font-size: 1.4rem;
    letter-spacing: 0.064em;
    text-align: right;
    margin-top: auto; }

.media {
  padding: 80px 0 100px; }
  .media__text {
    font-weight: bold;
    font-size: 1.8rem;
    letter-spacing: 0.04em;
    text-align: center;
    margin-top: 14px; }
    .media__text .fs-l {
      font-size: 2.8rem;
      color: #0032af;
      letter-spacing: 0.08em; }
  .media__logos {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 6px; }
  .media__logo {
    width: 21.875%;
    margin-top: 24px; }

.cv {
  position: relative;
  padding: 40px 0 40px;
  background-color: #0032af; }
  .cv::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: calc(100%);
    background-image: url("../img/bg_arrow.png");
    background-size: 1440px 826px;
    background-repeat: repeat;
    background-position: center center;
    display: block; }
  .cv__container {
    position: relative;
    max-width: 1340px; }
  .cv__content {
    position: relative;
    background-color: #fff;
    border-radius: 4px;
    padding: 64px 0 40px; }
    @media (max-width: 768px) {
      .cv__content {
        padding: 40px 16px; } }
    .cv__content::before {
      content: "";
      position: absolute;
      right: 0;
      top: -6px;
      padding-top: 28%;
      background-image: url("../img/cv_bg.png");
      background-size: contain;
      background-position: center center;
      background-repeat: no-repeat;
      width: 104.53%; }
  .cv__illust {
    position: absolute;
    top: 90px;
    left: -6%;
    width: 25%; }
    @media (max-width: 1080px) {
      .cv__illust {
        top: 30%;
        width: 16%; } }
    @media (max-width: 768px) {
      .cv__illust {
        top: 33%;
        width: 37%;
        left: -15%; } }
  .cv__content-inner {
    position: relative;
    max-width: 960px;
    width: 100%;
    margin: 0 auto; }
  .cv__title {
    font-size: 3.6rem;
    letter-spacing: 0.08em;
    font-weight: bold;
    color: #0032af;
    text-align: center; }
    @media (max-width: 768px) {
      .cv__title {
        font-size: 2.4rem; } }
  .cv__intro-text {
    font-size: 1.6rem;
    letter-spacing: 0.064em;
    text-align: center;
    margin-top: 14px; }
    @media (max-width: 768px) {
      .cv__intro-text {
        font-size: 1.4rem; } }
  .cv__2col {
    display: flex;
    margin-top: 40px; }
    @media (max-width: 768px) {
      .cv__2col {
        flex-wrap: wrap;
        margin-top: 20px; } }
  .cv__col {
    width: 50%;
    padding: 8px 20px 0 20px; }
    @media (max-width: 768px) {
      .cv__col {
        width: 100%;
        padding: 0; } }
    .cv__col:first-child {
      border-right: 1px solid #d0d0d0; }
      @media (max-width: 768px) {
        .cv__col:first-child {
          border-right: 0;
          border-bottom: 1px solid #d0d0d0;
          padding-bottom: 20px; } }
    @media (max-width: 768px) {
      .cv__col:last-child {
        padding-top: 20px; } }
  .cv__col-title {
    font-size: 1.8rem;
    letter-spacing: 0.04em;
    font-weight: bold;
    text-align: center; }
    @media (max-width: 768px) {
      .cv__col-title {
        font-size: 1.6rem; } }
  .cv__tel {
    color: #0032af;
    font-weight: bold;
    font-size: 1.6rem;
    letter-spacing: 0.08em;
    text-align: center;
    margin-top: 14px; }
    @media (max-width: 768px) {
      .cv__tel {
        margin-top: 6px; } }
  .cv__tel-link {
    font-size: 3.2rem; }
    @media (max-width: 768px) {
      .cv__tel-link {
        font-size: 2.4rem; } }
  .cv__tel-info {
    font-size: 1.4rem;
    letter-spacing: 0.04em;
    text-align: center;
    margin-top: 8px; }
  .cv__col-btn {
    width: 280px;
    height: 64px;
    margin: 35px auto 0; }
    @media (max-width: 768px) {
      .cv__col-btn {
        height: 56px;
        width: 100%;
        margin-top: 8px; } }

.flow {
  padding: 94px 0 80px; }
  @media (max-width: 768px) {
    .flow {
      padding: 40px 0; } }
  .flow__list {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap; }
    @media (max-width: 768px) {
      .flow__list {
        flex-direction: column;
        align-items: center;
        max-width: 320px;
        margin: 0 auto; } }
  .flow__item {
    position: relative;
    width: 17.9%;
    min-width: 150px;
    margin-right: 2.625%; }
    @media (max-width: 768px) {
      .flow__item {
        margin-right: 0;
        width: 100%; } }
    @media (max-width: 768px) {
      .flow__item:not(:last-child) {
        padding-bottom: 40px; }
        .flow__item:not(:last-child)::after {
          content: "";
          position: absolute;
          bottom: 6px;
          left: 0;
          right: 0;
          margin: 0 auto;
          width: 27px;
          height: 12px;
          transform: rotate(90deg);
          background-image: url("../img/icon_arrow_right_orange.svg");
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center center; } }
  .flow__item-num {
    display: block;
    font-family: "Brandon Grotesque", "Noto Sans JP", sans-serif;
    font-size: 6.4rem;
    letter-spacing: 0;
    color: #e4e9f2;
    margin-bottom: -50px; }
    @media (max-width: 768px) {
      .flow__item-num {
        font-size: 5.4rem; } }
  .flow__item-img {
    width: 76.16%;
    margin-left: auto; }
    @media (max-width: 768px) {
      .flow__item-img {
        width: 64%;
        margin: 0 auto; } }
  .flow__item-title {
    position: relative;
    text-align: center;
    font-weight: bold;
    color: #0032af;
    font-size: 1.8rem;
    letter-spacing: 0.04em;
    margin-top: 8px; }
    .flow__item-title::after {
      content: "";
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 27px;
      height: 12px;
      background-image: url("../img/icon_arrow_right_orange.svg");
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center center; }
      @media (max-width: 768px) {
        .flow__item-title::after {
          display: none; } }
  .flow__item-text {
    font-size: 1.4rem;
    line-height: 1.71;
    margin-top: 6px; }
  .flow__item:first-child .flow__item-title::after {
    right: -8%; }
  .flow__item:nth-child(2) .flow__item-title::after {
    right: -21%; }
  .flow__item:nth-child(3) .flow__item-title::after {
    right: -21%; }
  .flow__item:nth-child(4) .flow__item-title::after {
    right: -1%; }
  .flow__item:last-child {
    margin-right: 0; }
    .flow__item:last-child .flow__item-title::after {
      display: none; }
  .flow__bracket {
    margin-top: 64px; }
    @media (max-width: 768px) {
      .flow__bracket {
        margin-top: 24px; } }
    .flow__bracket .fs-s {
      font-size: 1.8rem;
      margin-top: 12px; }

.contact {
  background-color: #f5f5f5;
  padding: 94px 0 80px; }
  @media (max-width: 768px) {
    .contact {
      padding: 40px 0; } }
  .contact__container {
    max-width: 780px; }
  .contact__form {
    margin-top: 38px; }
    @media (max-width: 768px) {
      .contact__form {
        margin-top: 16px; } }

.page-thanks .thanks-mv {
  position: relative;
  padding: 160px 0 78px;
  min-height: calc(100vh - 204px);
  display: flex;
  align-items: center; }
  @media (max-width: 768px) {
    .page-thanks .thanks-mv {
      padding: 80px 0;
      min-height: calc(100vh - 183px); } }
  .page-thanks .thanks-mv__container {
    max-width: 1200px; }
  .page-thanks .thanks-mv__title-en {
    font-size: 8rem;
    font-weight: bold;
    letter-spacing: 0.02em;
    font-family: "Brandon Grotesque", "Noto Sans JP", sans-serif;
    text-align: left;
    line-height: 1.2; }
    @media (max-width: 1080px) {
      .page-thanks .thanks-mv__title-en {
        font-size: 6.4rem;
        letter-spacing: 0; } }
    @media (max-width: 768px) {
      .page-thanks .thanks-mv__title-en {
        font-size: 4.8rem; } }
  .page-thanks .thanks-mv__title {
    font-size: 3.2rem;
    font-weight: bold;
    letter-spacing: 0.12em;
    text-align: left;
    margin-top: 24px;
    color: #0032af; }
    @media (max-width: 768px) {
      .page-thanks .thanks-mv__title {
        font-size: 2rem; } }
  .page-thanks .thanks-mv__text {
    text-align: left;
    letter-spacing: 0.064em;
    font-size: 1.6rem;
    font-weight: bold;
    line-height: 2;
    margin-top: 28px; }
    @media (max-width: 768px) {
      .page-thanks .thanks-mv__text {
        font-size: 1.5rem;
        margin-top: 20px; } }
  .page-thanks .thanks-mv__btn-wrap {
    text-align: left;
    margin-top: 40px; }
    @media (max-width: 768px) {
      .page-thanks .thanks-mv__btn-wrap {
        margin-top: 24px; } }
  .page-thanks .thanks-mv__btn {
    width: 280px;
    height: 64px;
    margin: 0; }
    @media (max-width: 768px) {
      .page-thanks .thanks-mv__btn {
        height: 56px;
        width: 100%;
        max-width: 280px; } }
  .page-thanks .thanks-mv__btn-text {
    position: relative;
    margin-top: 8px;
    color: #004ad8;
    font-weight: bold;
    font-size: 1.4rem;
    letter-spacing: 0.064em;
    z-index: 1; }
  .page-thanks .thanks-mv__illust {
    position: absolute;
    right: 2.4rem;
    bottom: -12px;
    max-width: 32.6rem;
    width: 22.64%; }
  .page-thanks .thanks-mv__mail {
    color: #0032af;
    font-size: 2.4rem;
    display: inline-block; }
    @media (max-width: 768px) {
      .page-thanks .thanks-mv__mail {
        font-size: 2rem; } }

.js-inview {
  transition: 1s cubic-bezier(0.165, 0.84, 0.44, 1) 0.2s;
  transform: translateY(3rem);
  opacity: 0; }
  .js-inview.js-inview-active {
    transform: translateY(0);
    opacity: 1; }

.sp {
  display: none; }
  @media (max-width: 768px) {
    .sp {
      display: block; } }

@media (max-width: 768px) {
  .pc {
    display: none; } }

@media (max-width: 1080px) {
  .pc-l {
    display: none; } }

.pc-l-only {
  display: none; }
  @media (max-width: 1080px) {
    .pc-l-only {
      display: block; } }
  @media (max-width: 768px) {
    .pc-l-only {
      display: none; } }

.sp-s {
  display: none; }
  @media (max-width: 480px) {
    .sp-s {
      display: block; } }
